<template>
  <div>
    <div class="all_context">
      <div class="all_context_flex">
        <div class="storeCenter_item_top_left">
          <div class="banner_box">
            <img v-if="headData.headPortrait" :src="headData.headPortrait" alt="" />
          </div>
          <div class="storeCenter_item_top_left_flex">
            <div style="display: flex; align-items: center">
              <h5 class="h5_name" style="margin-right: 10px; width: auto">
                {{ headData.name }}
              </h5>
            </div>

            <div class="releaseMechanism">
              所在地区：{{ headData.province }}
              <span v-if="headData.city">-</span>
              {{ headData.city }}
            </div>
            <!-- <div class="mark_box">
              <div class="mark_box_flex" style="margin-left: 0">
                <span>本地约见：</span>
                <img src="@/assets/image/Vector-1.png" alt="" /><span
                  >{{ headData.localCompanyOriginalPrice }}元</span
                >
              </div>
              <div class="mark_box_flex" style="margin-left: 20px">
                <span>线上会议：</span>
                <img src="@/assets/image/Vector-1.png" alt="" /><span
                  >{{ headData.originalPrice }}元</span
                >
              </div>
            </div> -->
          </div>
        </div>
        <div class="right">
          <div class="total_num">
            <span>{{ userChecknum || 0 }}</span>
          </div>
          <div>
            <span>咨询人数</span>
          </div>
        </div>
      </div>

      <div class="context">
        <div class="state">
          <div class="left">
            <div class="contactStatus_box">
              <div class="title">咨询状态：</div>
              <div class="contactStatus_list">
                <div class="contactStatus" @click="checkContactStatus(null)">全部</div>
                <div class="contactStatus" @click="checkContactStatus(1)">已联系</div>
                <div class="contactStatus" @click="checkContactStatus(0)">未联系</div>
              </div>
            </div>
          </div>

          <div class="right">
            <div class="seach_box">
              <el-input
                placeholder="请输入姓名、企业名称"
                v-model="query.keys"
                class="input-with-select"
              >
                <el-button class="seach" slot="append" @click="search()">搜索</el-button>
              </el-input>
            </div>
          </div>
        </div>

        <div class="table">
          <el-table
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%"
            v-loading="loading"
            @sort-change="changeSort"
            element-loading-spinner="el-icon-loading"
            element-loading-text="加载中..."
            :header-cell-style="{ background: '#F8F9FB', color: '#595959' }"
          >
            <el-table-column width="100" prop="id" label="编号" align="center"> </el-table-column>
            <el-table-column width="140" label="微信头像" align="center">
              <template >
                <!-- <img v-if="row.headImgUrl" width="30" height="30" :src="row.headImgUrl" alt="" /> -->
                <img
                
                  idth="30"
                  height="30"
                  src="https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img_dev/202302061802.png"
                  class="img"
                  alt=""
                />
              </template>
            </el-table-column>
            <el-table-column width="120" prop="nickName" label="微信昵称" align="center">
            </el-table-column>
            <el-table-column prop="realName" label="姓名" align="center"> </el-table-column>

            <el-table-column width="120" prop="position" label="职位" align="center">
            </el-table-column>
            <el-table-column prop="phone" label="手机号" align="center"> </el-table-column>
            <el-table-column label="所在城市" align="center">
              <template v-slot="{ row }">
                <span>{{ row.city }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="companyFullName" label="企业全称" align="center">
              <template v-slot="{ row }">
                <span>{{ row.companyFullName }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="createTime"
              label="想咨询时间"
              sortable
              width="180"
              align="center"
            ></el-table-column>
            <el-table-column
            v-if="headData.specialistChannel=='2'"
              label="操作"
              align="center"
              class-name="small-padding fixed-width"
              width="200px"
            >
              <template slot-scope="scope">
                <el-button v-if="scope.row.contactStatus == '1'" style="color: #999999" type="text"
                  >已联系
                </el-button>

                <el-button
                  v-else
                  style="color: cornflowerblue"
                  type="text"
                  @click="torecord(scope.row)"
                  >未联系
                </el-button>
              </template>
            </el-table-column>
          </el-table>

          <div class="Pagination">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="query.size"
              :page-sizes="[10, 30, 50]"
              :page-size="query.pageNum"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { listUserAbout, selectById, userAboutCount, concatUser } from '@/api/specialist.js'
export default {
  name: 'consultantsNumber',
  computed: {
    activeId() {
      return this.$route.query.id
    }
  },
  data() {
    //这里存放数据
    return {
      headData: {}, ///头部信息
      // 表格数据
      tableData: [],
      loading: false,
      // 分页总条数
      total: 0,
      ids: [],
      // 表格发送数据
      query: {
        city: '',
        specialistId: '',
        checkTime: '', //>1分钟以下;2->2分钟以下;3->3分钟以上
        keys: '', //名/企业名称
        contactStatus: null,
        pageNum: 1,
        pageSize: 10,
        orders: '1' //排序 默认传1 2->时长升序;3->时长降序;4->时间降序;5->时间升序
      },
      userChecknum: 0 //查看数
    }
  },

  created() {
    this.getHeadData()
    this.search()
    this.userAboutCount()
  },
  //方法集合
  methods: {
    // 切换列表筛选条件
    checkContactStatus(val) {
      if (this.query.contactStatus == val) {
        return
      }
      this.query.contactStatus = val
      this.search()
    },
    // 联系
    async torecord(row) {
      const res = await concatUser({ id: row.id })
      if (res.code == 200) {
        this.$message.success('联系成功！')
        this.search()
      }
    },
    //排序
    changeSort(val) {
      //val里面返回prop和order
      if (val.prop == 'createTime') {
        if (val.order == 'ascending') {
          //updateAt列升序时
          this.queryInfo.orders = '3'
          this.search()
        } else if (val.order == 'descending') {
          //updateAt列降序时

          this.queryInfo.orders = '2'
          this.search()
        } else {
          //updateAt列不排序时
          this.queryInfo.orders = '1'
          this.search()
        }
      }
    },
    //用户查看数
    async userAboutCount() {
      const query = {
        specialistId: this.activeId
      }
      const   res  = await userAboutCount(query)
      if (res.code == 200) {
        this.userChecknum = res.data
      }
    },
    handleSizeChange(val) {
      this.query.pageSize = val
      this.search()
    },
    handleCurrentChange(val) {
      this.query.pageNum = val
      this.search()
    },

    // 头部数据
    async getHeadData() {
      const  res  = await selectById({
        id: this.activeId
      })
      if (res.code == 200) {
        this.headData = res.data
      }
    },

    // table表格按钮的选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map((item) => item.id)
    },
    async search() {
      this.query.specialistId = this.activeId
      this.loading = true
      const   res  = await listUserAbout(this.query)

      if (res.code == 200) {
        this.tableData = res.data.list
        this.total = res.data.total
        this.loading = false
      }
    }
  }
}
</script>
<style scoped lang="less">
.all_context {
  padding: 16px 0px 0 0px;
  .all_context_flex {
    display: flex;
    background-color: #ffffff;
    padding-right: 50px;
    border-radius: 8px;
    padding: 20px;
    position: relative;
    margin-bottom: 20px;
    .right {
      position: absolute;
      right: 100px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .total_num {
        margin-bottom: 10px;
        font-size: 30px;
        font-weight: 550;
        color: #ff7d18;
      }
    }
  }
  .storeCenter_item_top_left {
    display: flex;
    flex-direction: row;
    height: 107.73px;
    width: 450px;
    .banner_box {
      position: relative;
      width: 185.55px;
      height: 117.73px;
      .generalVersion {
        background: #448aff;
        border-radius: 2px;
        padding: 5px;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        /* identical to box height */
        margin: 0 10px;
        color: #ffffff;
        position: absolute;
        left: 0px;
        top: 5px;
      }
      img {
        width: 185.55px;
        height: 117.73px;
        border-radius: 4px;
        margin-right: 14px;
      }
      .activity_state {
        position: absolute;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 34px;
        text-align: center;
        color: #ffffff;
        top: -10px;
        left: -9px;
        width: 78px;
        height: 34px;
        border-radius: 5px 15px 15px 0;
      }
      .not_started {
        background: #4e93fb;
      }
      .have_in_hand {
        background: #ff7d18;
      }
      .closed {
        background: #999999;
      }
    }

    .storeCenter_item_top_left_flex {
      margin-left: 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .h5_name {
        display: inline-block;
        margin: 0;
        font-size: 18px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        width: 200px;
        -webkit-box-orient: vertical;
        margin-left: 5px;
        cursor: pointer;
      }
      .h5_names {
        display: inline-block;
        margin: 0;
        font-size: 17px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        width: 320px;
        -webkit-box-orient: vertical;
        cursor: pointer;
      }
      .mark_box {
        display: flex;
        img {
          width: 14px;
          // height: 14px;
        }
        & > div {
          margin-left: 10px;
        }
      }
      .img_commer {
        display: flex;
        border: 1px solid #146aff;
        border-radius: 39px;
        .name {
          font-size: 12px;
          line-height: 20px;
          color: #146aff;
        }
        .color_img {
          background: #146aff;
          height: 20px;
          width: 25px;
          border-radius: 20px;
          position: relative;
          left: -10px;
          img {
            width: 13px;
            height: 13px;
            position: relative;
            left: 5px;
            top: 2px;
          }
        }
      }
      .img_commers {
        display: flex;
        border: 1px solid #ff9c00;
        border-radius: 39px;
        margin: 0px 10px;
        .name {
          font-size: 12px;
          line-height: 20px;
          color: #ff9c00;
        }
        .color_img {
          background: #ff9c00;
          height: 20px;
          width: 25px;
          border-radius: 20px;
          position: relative;
          left: -10px;
          img {
            width: 13px;
            height: 13px;
            position: relative;
            left: 5px;
            top: 2px;
          }
        }
      }

      h4 {
        color: #1f2d3d;
        font-size: 26px;
        font-weight: 500;
        margin: 0;
      }

      .storeCenter_item_top_left_flex_tag {
        padding-top: 10px;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-content: center;

        margin-bottom: 1px;
        width: 500px;

        .hidden_label_box {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-content: center;
          flex-wrap: wrap;
        }
        span {
          box-sizing: border-box;
          display: block;
          font-size: 14px;
          font-weight: 400;
          border-radius: 4px;
        }

        .office {
          border: 1px solid #559eff;
          color: #4e93fb;
          padding: 1px 6px;
          margin-right: 10px;
        }

        .support {
          border: 1px solid #fd523f;
          color: #fd523f;
          margin-left: 12px;
        }
      }

      .releaseMechanism {
        width: 450px;
        padding: 10px 0px;
        font-size: 16px;
      }
    }
  }
  .seach_box {
    display: flex;
    margin: 15px 0;
    align-items: flex-end;
    .input-with-select {
      width: 400px;
    }

    .seach {
      background-color: #448aff;
      color: #fff;
      border-radius: 0;
      border: 1px solid #448aff;
    }
  }

  .context {
    background-color: #ffffff;
    padding: 0 21px 0 30px;
    border-radius: 8px;
    .pass {
      color: #4e93fb;
      cursor: pointer;
    }
    .un_pass {
      color: #fd6161;
      cursor: pointer;
    }
    .state {
      height: 72px;
      border-bottom: 1px dashed #f3f3f3;
      display: flex;
      box-sizing: border-box;
      align-items: center;
      justify-content: space-between;

      .left {
        margin-top: 20px;
        display: flex;
        box-sizing: border-box;
        align-items: center;
        .contactStatus_box {
          display: flex;
          font-family: PingFang SC;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0em;
          text-align: left;
          .title {
            margin-right: 20px;
            color: rgba(124, 127, 142, 1);
          }
          .contactStatus_list {
            display: flex;
            .contactStatus {
              margin-right: 30px;
              cursor: pointer;
            }
          }
        }
        .label {
          font-size: 14px;
          color: #7c7f8e;
        }
        .item {
          color: #333333;
          font-size: 14px;
          cursor: pointer;
          padding: 15px 20px;
        }
      }
      .right {
        display: flex;
        padding-right: 25px;
        f .icon {
          width: 20px;
          height: 20px;

          img {
            width: 100%;
            height: 100%;
          }
        }
        .tex {
          margin-left: 7px;
          color: #595959;
        }
        .time {
          color: #333333;
        }
      }
      /deep/.el-radio {
        margin-right: 0;
      }
    }
    .time_search {
      height: 80px;
      display: flex;
      align-items: center;
      padding-right: 10px;
      justify-content: space-between;
      position: relative;
      .time {
        display: flex;
        box-sizing: border-box;
        align-items: center;
        position: absolute;
        left: 0;

        .label {
          font-size: 14px;
          color: #7c7f8e;
        }
        // .item {
        //   color: #333333;
        //   font-size: 14px;
        //   cursor: pointer;
        //   padding: 15px 20px;
        // }
      }
      .search {
        display: flex;
        position: absolute;
        right: 0;
        .search_input {
          width: 430px;
        }
        .search_div {
          width: 74px;
          height: 40px;
          background-color: #448aff;
          color: #fff;
          border-radius: 2px;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          transform: translateX(-2px);
        }
      }
    }

    .table {
      .imgbox {
        display: flex;
        justify-content: center;

        .img {
          width: 34px;
          height: 34px;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }

      .correlationBox {
        display: flex;
        align-items: center;
        justify-content: center;
        .correlation {
          background-color: #fd6161;
          border-radius: 2px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 55px;
          height: 24px;
          color: #fff;
          font-size: 12px;
        }
      }

      .enterpriseIntro {
        // width: 100%;
        // height: 100%;
        width: 376px;
        overflow: hidden; //超出隐藏
        text-overflow: ellipsis; //溢出用省略号显示
        display: -webkit-box; // 将对象作为弹性伸缩盒子模型显示。
        // 控制行数
        -webkit-line-clamp: 2; //超出两行隐藏
        -webkit-box-orient: vertical; // 从上到下垂直排列子元素
        color: #333333;
        position: relative;
      }
      .enterpriseIntroShow {
        position: absolute;
        // right: 0%;
        // bottom: -23%;
        border-radius: 8px;
        // transform: translateX(50%);
        width: 376px;
        padding: 10px 7px 8px 13px;
        background-color: #fff;
        z-index: 999;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
      }

      /deep/.el-table__body-wrapper {
        overflow: visible;
      }
      /deep/.el-table {
        overflow: visible;
      }
    }
    .Pagination {
      width: 100%;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      padding: 50px 0 50px 0;
    }
  }
}

.add_select {
  .remove_pool {
    box-sizing: border-box;
    width: 348px;
    height: 150px;
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 4px;
    box-shadow: 0px 0px 2px #d4d4d4;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    padding: 42px 0 20px 0;
    align-items: center;
    .el-icon-close {
      position: absolute;
      right: 11px;
      top: 11px;
      color: #b6b6b4;
      font-size: 18px;
      cursor: pointer;
    }
    .text {
      margin-bottom: 23px;
    }
    /deep/.el-button {
      width: 112px;
      height: 40px;
    }
  }
  /deep/.el-dialog {
    margin-top: 40vh !important;
  }
}

.correlation {
  /deep/.el-dialog {
    border-radius: 4px;
    position: relative;
  }
  /deep/.el-dialog__body {
    padding: 28px 20px 16px 20px;
  }
  .correlationBox {
    .head {
      position: absolute;
      left: 0;
      top: 12px;
      // transform: translate(-20px, -50px);
      .title {
        border-left: 4px solid #448aff;
        padding-left: 15px;
        font-size: 18px;
        font-weight: 550;
        color: #151515;
      }
    }
    .context {
      .input {
        display: flex;
        justify-content: space-around;
        align-items: center;
        .label {
          position: relative;
          font-size: 14px;
          color: #333333;
          span {
            position: absolute;
            right: -9px;
            top: -2px;
            color: #f05252;
          }
        }
        /deep/.el-input {
          width: 322px;
        }
      }
      .button {
        width: 100%;
        display: flex;
        justify-content: center;
        .text {
          width: 64px;
          height: 36px;
          cursor: pointer;
          border-radius: 2px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          font-size: 14px;
          background-color: #448aff;
          margin-top: 31px;
        }
      }
    }
  }
}
</style>
