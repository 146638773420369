import request from '@/utils/request'
//专家一览查询
export function listPage(query) {
  return request({
    url: '/specialistGinseng/listPage',
    method: 'get',
    params: query
  })
}

//批量设置专家下架
export function updateOffShelf(data) {
  return request({
    url: '/specialistGinseng/specialistUnShelve',
    method: 'post',
    data
  })
}
//批量设置专家上架
export function updateOnShelf(data) {
  return request({
    url: '/specialistGinseng/specialistGrounding',
    method: 'post',
    data
  })
}
//专家冻结
export function expertFreeze(data) {
  return request({
    url: '/specialistGinseng/specialistFreeze',
    method: 'post',
    data
  })
}
//专家解冻
export function expertThawing(data) {
  return request({
    url: '/specialistGinseng/specialistThawing',
    method: 'post',
    data
  })
}
//专家新增
export function save(data) {
  return request({
    url: '/specialistGinseng/save',
    method: 'post',
    data
  })
}
//专家修改
export function update(data) {
  return request({
    url: '/specialistGinseng/update',
    method: 'post',
    data
  })
}
//专家详情
export function listById(query) {
  return request({
    url: '/specialistGinseng/listById',
    method: 'get',
    params: query
  })
}
//专家查看人数
export function userCheck(query) {
  return request({
    url: '/specialistGinseng/userCheckCount',
    method: 'get',
    params: query
  })
}
//专家查看一览查询
export function listUserCheckPage(query) {
  return request({
    url: '/specialistGinseng/listUserCheck',
    method: 'get',
    params: query
  })
}
//专家详情
export function selectById(query) {
  return request({
    url: '/specialistGinseng/listById',
    method: 'get',
    params: query
  })
}
//专家分享人数
export function userShareCount(query) {
  return request({
    url: '/specialistGinseng/userShareCount',
    method: 'get',
    params: query
  })
}
//专家分享一览查询
export function listUserShare(query) {
  return request({
    url: '/specialistGinseng/listUserShare',
    method: 'get',
    params: query
  })
}
//约聊一览查询
export function listUserAbout(query) {
  return request({
    url: '/specialistGinseng/listUserAbout',
    method: 'get',
    params: query
  })
}
//约聊人数
export function userAboutCount(query) {
  return request({
    url: '/specialistGinseng/userAboutCount',
    method: 'get',
    params: query
  })
}
//运营人员修改用户联系专家的状态
export function concatUser(query) {
  return request({
    url: '/specialistGinseng/concatUser',
    method: 'get',
    params: query
  })
}
